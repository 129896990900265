.order-form {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}

h2 {
  text-align: center;
  margin-bottom: 20px;
}

table {
  border-collapse: collapse;
  width: 100%;
}

th, td {
  border: 1px solid black;
  padding: 8px;
  text-align: left;
}

th {
  background-color: #f2f2f2;
}

/* Add vertical line between table columns */
th:not(:last-child),
td:not(:last-child) {
  border-right: 1px solid black;
}


img {
  max-width: 100px;
  height: auto;
}

select {
  padding: 5px;
}

button {
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  cursor: pointer;
}

button:hover {
  background-color: #45a049;
}

.order-summary {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 20px;
}

.order-summary span {
  margin-bottom: 10px;
}

.order-summary .total-price {
  font-weight: bold;
  margin-top: 20px;
}

.order-button {
  margin-top: 20px;
}

.order-button:disabled {
  background-color: #ccc; /* Change to a different color or style for disabled button */
  cursor: not-allowed;
}

.total-price {
  margin-right: 20px;
}

.order-button {
  margin-left: 10px;
}

.product-name {
  font-weight: bold;
  font-size: 1.2em;
  color: #333;
}

.product-description {
  font-size: 0.9em;
  color: #555;
  line-height: 1.4;
}